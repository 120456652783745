<template>
  <div id="FamilyDetail">
    <!-- 导航栏 -->
    <van-cell style="padding-top: 17px;padding-bottom: 17px;">
      <template #title>
        <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{form.year +'年'}}</span>家庭情况调查</span>
      </template>
      <template #label>
        <div class="custom-centent  hidden-text">
          <svg-icon icon-class="date" size="15" /> 时间: {{parseTime(form.createTime, "{y}-{m}-{d}")}}
        </div>
        <div class="custom-centent margin6 hidden-text">
          <svg-icon icon-class="unit" size="15" /> 等级: {{(form.rankName === '01' || form.rankName === '02' || form.rankName === '03') ? form.rankName:'待认定'}}
        </div>
        <div class="custom-centent margin6 hidden-text">
          <svg-icon icon-class="unit" size="15" /> 评议: {{(form.reviewName === '01' || form.reviewName === '02') ? form.reviewName:'待评议'}}
        </div>
      </template>
    </van-cell>

    <!-- 审核信息 -->
    <van-cell-group title="审核信息">
      <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'JZ',coreCode:'PKSRD' , status:$route.query.status,level:3}" />
    </van-cell-group>

    <van-form ref="form" style="margin-bottom:64px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.gradeNo" readonly label="年级" />
        <van-field v-model="form.unitName" readonly label="院系" />
        <van-field v-model="form.specName" readonly label="专业" />
        <van-field v-model="form.className" readonly label="班级" />
        <van-field v-model="form.cardNo" readonly label="身份证号" />
        <van-field v-model="form.sexName" readonly label="性别" />
        <van-field v-model="form.enterMon" readonly label="入学年月" />
        <van-field v-model="form.bdDate" readonly label="出生日期" />
        <van-field v-model="form.nativePlaceNo" readonly label="籍贯" />
        <van-field v-model="form.famCnt" readonly label="家庭人口数" />
        <van-field v-model="form.telNo" readonly label="联系电话" />
      </van-cell-group>
      <!-- 家庭信息 -->
      <van-cell-group title="家庭信息">
        <van-field v-model="form.addressNo" readonly label="家庭地址" />
        <van-field v-model="form.homeAddress" readonly label="详细地址" />
        <van-field v-model="form.homePostcode" readonly label="邮政编码" />
        <van-field v-model="form.mobileOfParent1" readonly label="家长手机号1" />
        <van-field v-model="form.mobileOfParent2" readonly label="家长手机号2" />
        <van-field v-model="form.housingNo" readonly label="住房情况" />
        <van-field v-model="form.housingText" readonly v-if="form.housingNo === '99'" placeholder="请填写住房情况备注" label="住房情况备注" />
        <van-field v-model="form.carPurchaseNo" readonly label="购车情况" />
        <van-field v-model="form.carPurchaseText" readonly v-if="form.carPurchaseNo === '99'" placeholder="请填写购车情况备注" label="购车情况备注" />
      </van-cell-group>
      <!-- 家庭成员情况 -->
      <van-cell-group title="家庭成员情况">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.jtcyxxList" :key="index" :title="item.name+'('+item.relation+')'" :name="index">
            <div> <span class="collapse-title">身份证号码:</span><span class="collapse-text">{{item.cardNo}}</span></div>
            <div> <span class="collapse-title">工作单位:</span><span class="collapse-text">{{item.unitName}}</span></div>
            <div> <span class="collapse-title">职业:</span><span class="collapse-text">{{item.occupation}}</span></div>
            <div> <span class="collapse-title">收入:</span><span class="collapse-text">{{item.annualIncome}}</span></div>
            <div> <span class="collapse-title">健康状态:</span><span class="collapse-text">{{codeConvertLabel(item.healthNo,dict.ctry_base_health_stat)}}</span></div>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
      <!-- 特殊群体类型 -->
      <van-cell-group title="特殊群体类型">
        <van-field readonly v-model="form.needyStu" class="radio-other-info" label="建档立卡贫户" />
        <van-field readonly v-model="form.destituteStu" class="radio-other-info" label="特困供养学生" />
        <van-field readonly v-model="form.orphan" class="radio-other-info" label="孤儿" />
        <van-field readonly v-model="form.martyrStu" class="radio-other-info" label="烈士子女" />
        <van-field readonly v-model="form.deformityStu" class="radio-other-info" label="残疾学生及残疾人子女" />
        <van-field readonly v-model="form.safeguardStu" class="radio-other-info" label="最低生活保障家庭" />
        <van-field readonly v-model="form.other" class="radio-other-info" label="其他原因" />
      </van-cell-group>
      <!-- 影响家庭经济状况有关信息 -->
      <van-cell-group title="影响家庭经济状况有关信息" class="family-yxjjzk-cell">
        <div class="form-label" style="padding-top: 10px;">人均年收入（元）</div>
        <van-field readonly v-model="form.annualIncome" />
        <div class="form-label">家庭遭受自然灾害情况</div>
        <van-field readonly v-model="form.annualDisastersText" />
        <div class="form-label">家庭遭受突发意外事件</div>
        <van-field readonly v-model="form.accidentText" />
        <div class="form-label">家庭成员因残疾、年迈而劳动能力弱情况 </div>
        <van-field readonly v-model="form.deformity" />
        <div class="form-label">家庭成员失业情况 </div>
        <van-field readonly v-model="form.unemployedText" />
        <div class="form-label">家庭欠债情况 </div>
        <van-field readonly v-model="form.debtDueText" />
        <div class="form-label">其他情况 </div>
        <van-field readonly v-model="form.otherText" />
      </van-cell-group>
      <!-- 学生在校期间受资助情况 -->
      <van-cell-group title="学生在校期间受资助情况">
        <van-collapse v-model="activeName2s">
          <van-collapse-item v-for="item,index in form.zxzzqkList" :key="index" :title="item.type ==='DQ' ?'本年度拟申请资助项目':item.year+'年'" :value="item.subsidize ?'¥ '+item.subsidize:''" :name="index">
            <div> <span class="collapse-title">受资助项目:</span><span class="collapse-text">{{item.funPro}}</span></div>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="其他信息">
        <van-field v-model="form.promise" rows="3" readonly class="textarea-style" arrow-direction="" label="个人承诺" type="textarea" maxlength="300" placeholder="请填写个人承诺" />
      </van-cell-group>
    </van-form>

    <div class="submit-button shadow-style">
      <div>
        <van-button round block type="warning" :loading="loading" @click="doDownload()">{{"下载"+(form.year?form.year+'年':"")+'家庭情况调查表'}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statustag, parseTime } from "@/utils";
import { convertProvinceName } from "@/api/common/common";
import { exportPdf } from "@/api/modules/student/pksxx";
import audit from "@/view/modules/common/audit";
import { simulationClickBlob } from "@/api/system/storage";

export default {
  name: "FamilyForm",
  components: { audit },
  dicts: [
    "xj_zfqk",
    "xj_gcqk",
    "xj_xb",
    "xj_sf",
    "xj_tsqtyy",
    "xj_nj",
    "xj_shzt",
    "ctry_base_health_stat",
  ],
  data() {
    return {
      // 家庭信息弹出层列表默认展开第几个
      activeNames: [0],
      activeName2s: [0],
      loading: false,
      form: {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别码
        sexCode: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 家庭地址代码
        addressNo: "",
        // 家庭地址名称
        addressName: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        homePostcode: "",
        // 家长手机号1
        mobileOfParent1: "",
        // 家长手机号1
        mobileOfParent2: "",
        // 住房情况代码
        housingNo: "01",
        // 住房情况名称
        housingName: "",
        // 住房情况备注
        housingText: "",
        // 购车情况代码
        carPurchaseNo: "01",
        // 购车情况名称
        carPurchaseName: "",
        // 购车情况备注
        carPurchaseText: "",
        // 建档立卡贫困家庭学生
        needyStu: "0",
        // 特困供养学生
        destituteStu: "0",
        // 孤儿
        orphan: "0",
        // 最低生活保障家庭学生
        safeguardStu: "0",
        // 烈士子女
        martyrStu: "0",
        // 家庭经济困难残疾学生及残疾人子女
        deformityStu: "0",
        // 其他原因
        other: "04",
        // 人均年收入
        annualIncome: "",
        // 家庭遭受自然灾害情况
        annualDisastersText: "",
        // 家庭遭受突发意外事件
        accidentText: "",
        // 家庭成员因残疾、年迈而劳动能力弱情况
        deformity: "",
        // 家庭成员失业情况
        unemployedText: "",
        // 家庭欠债情况
        debtDueText: "",
        // 其他情况
        otherText: "",
        // 个人承诺
        promise: "",
        // 评议状态代码
        reviewNo: "",
        // 评议状态名称
        reviewName: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        zxzzqkList: [
          {
            id: "",
            year: new Date().getFullYear().toString(),
            funPro: "",
            subsidize: "",
            pksxxid: "",
            type: "DQ",
          },
        ],
        status: 0,
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  methods: {
    parseTime,
    statustag,
    codeConvertLabel(code, dicts) {
      if (code) {
        return dicts
          .filter((item) => {
            return item.value + "" === code + "";
          })
          .map((item) => {
            return item.label;
          })
          .toString();
      }
      return "";
    },
    async initData() {
      this.form.stuNo = this.user.username;
      if (this.$route.query && this.$route.query.id) {
        let data = this.$route.query;
        this.form = data;
        if (data.bdDate) {
          this.form.bdDate = parseTime(data.bdDate, "{y}-{m}-{d}");
        }
        this.form.needyStu = parseInt(data.needyStu) === 0 ? "否" : "是";
        this.form.destituteStu =
          parseInt(data.destituteStu) === 0 ? "否" : "是";
        this.form.orphan = parseInt(data.orphan) === 0 ? "否" : "是";
        this.form.martyrStu = parseInt(data.martyrStu) === 0 ? "否" : "是";
        this.form.deformityStu =
          parseInt(data.deformityStu) === 0 ? "否" : "是";
        this.form.safeguardStu =
          parseInt(data.safeguardStu) === 0 ? "否" : "是";

        if (data.nativePlaceNo) {
          convertProvinceName({ code: data.nativePlaceNo }).then((res) => {
            this.form.nativePlaceNo = res;
          });
        }
        if (data.addressNo) {
          convertProvinceName({ code: data.addressNo }).then((res) => {
            this.form.addressNo = res;
          });
        }

        setTimeout(() => {
          if (data.other && this.dict.xj_tsqtyy) {
            let name = this.codeConvertLabel(data.other, this.dict.xj_tsqtyy);
            this.form.other = name;
          }
          // 业务ID 审批项目类型 审批业务类型 目前到审批状态
          this.$refs["audit"].init(
            data.id,
            "JZ",
            "PKSRD",
            parseInt(data.spzt),
            3
          );
        }, 1000);
      }
    },
    doDownload() {
      this.loading = true;
      exportPdf(this.form.id).then((result) => {
        simulationClickBlob(result, this.form.stuName + "贫困生申请表.pdf");
        this.loading = false;
      });
    },
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt
        .filter((item) => {
          return parseInt(item.value) === approvalStatus;
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
    onClickLeft() {
      this.form = {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别码
        sexCode: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 家庭地址代码
        addressNo: "",
        // 家庭地址名称
        addressName: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        homePostcode: "",
        // 家长手机号1
        mobileOfParent1: "",
        // 家长手机号1
        mobileOfParent2: "",
        // 住房情况代码
        housingNo: "01",
        // 住房情况名称
        housingName: "",
        // 住房情况备注
        housingText: "",
        // 购车情况代码
        carPurchaseNo: "01",
        // 购车情况名称
        carPurchaseName: "",
        // 购车情况备注
        carPurchaseText: "",
        // 建档立卡贫困家庭学生
        needyStu: "0",
        // 特困供养学生
        destituteStu: "0",
        // 孤儿
        orphan: "0",
        // 最低生活保障家庭学生
        safeguardStu: "0",
        // 烈士子女
        martyrStu: "0",
        // 家庭经济困难残疾学生及残疾人子女
        deformityStu: "0",
        // 其他原因
        other: "04",
        // 人均年收入
        annualIncome: "",
        // 家庭遭受自然灾害情况
        annualDisastersText: "",
        // 家庭遭受突发意外事件
        accidentText: "",
        // 家庭成员因残疾、年迈而劳动能力弱情况
        deformity: "",
        // 家庭成员失业情况
        unemployedText: "",
        // 家庭欠债情况
        debtDueText: "",
        // 其他情况
        otherText: "",
        // 个人承诺
        promise: "",
        // 评议状态代码
        reviewNo: "",
        // 评议状态名称
        reviewName: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        zxzzqkList: [
          {
            id: "",
            year: new Date().getFullYear().toString(),
            funPro: "",
            subsidize: "",
            pksxxid: "",
            type: "DQ",
          },
        ],
        status: 0,
      };
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/student/family.scss";
::v-deep.radio-other-info {
  .van-field__label {
    width: 11.2em;
  }
}
::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  text-align: center;
  &-savebtn {
    margin-left: -5%;
  }

  &-subbtn {
    margin-left: 5%;
  }

  .van-button {
    margin-top: 10px;
    margin-bottom: 9px;
    width: 40%;
  }

  .van-button--block {
    display: inline-block !important;
  }
}
.audit {
  top: 17px;
  right: 10px;
  border-radius: 0 13px 0 13px;
}
</style>